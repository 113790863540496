import {OPEN_TYPE ,ASSESMENT_FILTER, SHORT_ASSESMENT_FILTER } from '../type'
import service from '../../interceptor'
import { FETCH_ASSESMENT_LIST } from '../type'
import { APIS_URL} from '../../utils/apiEndPoints'


//  Assesment Detail Page
export const assesmentDetail = (data) => {
    return {
            type : OPEN_TYPE,
            payload : data
        }
}

export const assesmentFilter = (data) => {
    return {
         type : ASSESMENT_FILTER,
         payload : data
    }
}



export const shortAssesmentFilter = (data) => {
    return {
         type : SHORT_ASSESMENT_FILTER,
         payload : data
    }
}



// Fetch Assesment List
export const assesmentList = (reqBody) => dispatch => {

    return new Promise( (resolve, reject) => {

        let  { page , per_page_items , status , clinic_provider_id, clinician_name, clinic_name , clinic_id , created_date , corporate_name, category_type, used_date , search , sort_by , order , download_excel, tz_value , survey_type, is_public } = reqBody

        console.log(clinic_id, 'clinic_id')

        let query = `?clinician_name=${encodeURIComponent(clinician_name)}&tz_value=${tz_value}&status=${status}&clinic_id=${clinic_id == 'all' ? '' : clinic_id}&page=${page}&per_page_items=${per_page_items}&created_date=${created_date}&used_date=${used_date}&search=${search}&sort_by=${sort_by}&order=${order}&is_public=${is_public}&clinic_provider_id=${clinic_provider_id}&category_type=${category_type}&corporate_name=${corporate_name}&clinic_name=${encodeURIComponent(clinic_name)}`
        service.get(`${APIS_URL.ASSESMENT_LIST}${query}`).then( (res) => {  
            dispatch({
                type : FETCH_ASSESMENT_LIST,
                payload : res.content   
            })
            resolve(res)
        })
    })
}

export const assesmentShortList = (reqBody) => dispatch => {

    return new Promise( (resolve, reject) => {

        let  { page , per_page_items , status , corporate_name, category_type, clinic_provider_id, clinician_name, clinic_name , clinic_id , created_date , used_date , search , sort_by , order , download_excel, tz_value , survey_type, is_public } = reqBody

        
        let query = `?is_short=true&clinician_name=${encodeURIComponent(clinician_name)}&tz_value=${tz_value}&status=${status}&clinic_id=${clinic_id == 'all' ? '' : clinic_id}&page=${page}&per_page_items=${per_page_items}&created_date=${created_date}&used_date=${used_date}&search=${search}&sort_by=${sort_by}&order=${order}&is_public=${true}&clinic_provider_id=${clinic_provider_id}&category_type=${category_type}&corporate_name=${corporate_name}&clinic_name=${encodeURIComponent(clinic_name)}`
        service.get(`${APIS_URL.ASSESMENT_LIST}${query}`).then( (res) => {  
            dispatch({
                type : FETCH_ASSESMENT_LIST,
                payload : res.content   
            })
            resolve(res)
        })
    })
}